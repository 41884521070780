export const images = [
  { src: "/Default_thumbnail.jpg" },
  {
    src: "/Thumbnail_0001.jpg"
  },
  {
    src: "/Thumbnail_0002.jpg"
  },
  {
    src: "/Thumbnail_0003.jpg"
  },
  {
    src: "/Thumbnail_0004.jpg"
  },
  {
    src: "/Thumbnail_0005.jpg"
  },
  {
    src: "/Thumbnail_0006.jpg"
  },
  {
    src: "/Thumbnail_0007.jpg"
  },
  {
    src: "/Thumbnail_0008.jpg"
  },
  {
    src: "/Thumbnail_0009.jpg"
  },
  {
    src: "/Thumbnail_0010.jpg"
  },
  {
    src: "/Thumbnail_0011.jpg"
  },
  {
    src: "/Thumbnail_0012.jpg"
  },
  {
    src: "/Thumbnail_0013.jpg"
  },
  {
    src: "/Thumbnail_0014.jpg"
  },
  {
    src: "/Thumbnail_0015.jpg"
  },
  {
    src: "/Thumbnail_0016.jpg"
  },
  {
    src: "/Thumbnail_0017.jpg"
  },
  {
    src: "/Thumbnail_0018.jpg"
  },
  {
    src: "/Thumbnail_0019.jpg"
  },
  {
    src: "/Thumbnail_0020.jpg"
  },
  {
    src: "/Thumbnail_0021.jpg"
  },
  {
    src: "/Thumbnail_0022.jpg"
  },
  {
    src: "/Thumbnail_0023.jpg"
  },
  {
    src: "/Thumbnail_0024.jpg"
  },
  { src: "/Thumbnail_0025.jpg" },
  { src: "/Thumbnail_0026.jpg" },
  { src: "/Thumbnail_0027.jpg" },
  { src: "/Thumbnail_0028.jpg" },
  { src: "/Thumbnail_0029.jpg" },
  { src: "/Thumbnail_0030.jpg" },
  { src: "/Thumbnail_0031.jpg" },
  { src: "/Thumbnail_0032.jpg" },
  { src: "/Thumbnail_0033.jpg" },
  { src: "/Thumbnail_0034.jpg" },
  { src: "/Thumbnail_0035.jpg" },
  { src: "/Thumbnail_0036.jpg" },
  { src: "/Thumbnail_0037.jpg" },
  { src: "/Thumbnail_0038.jpg" },
  { src: "/Thumbnail_0039.jpg" },
  { src: "/Thumbnail_0040.jpg" },
  { src: "/Thumbnail_0041.jpg" },
  { src: "/Thumbnail_0042.jpg" },
  { src: "/Thumbnail_0043.jpg" },
  { src: "/Thumbnail_0044.jpg" },
  { src: "/Thumbnail_0045.jpg" },
  { src: "/Thumbnail_0046.jpg" },
  { src: "/Thumbnail_0047.jpg" },
  { src: "/Thumbnail_0048.jpg" },
  { src: "/Thumbnail_0049.jpg" },
  { src: "/Thumbnail_0050.jpg" },
  { src: "/Thumbnail_0051.jpg" },
  { src: "/Thumbnail_0052.jpg" },
  { src: "/Thumbnail_0053.jpg" },
  { src: "/Thumbnail_0054.jpg" },
  { src: "/Thumbnail_0055.jpg" },
  { src: "/Thumbnail_0056.jpg" },
  { src: "/Thumbnail_0057.jpg" },
  { src: "/Thumbnail_0058.jpg" },
  { src: "/Thumbnail_0059.jpg" },
  { src: "/Thumbnail_0060.jpg" },
  { src: "/Thumbnail_0061.jpg" },
  { src: "/Thumbnail_0062.jpg" },
  { src: "/Thumbnail_0063.jpg" },
  { src: "/Thumbnail_0064.jpg" },
  { src: "/Thumbnail_0065.jpg" },
  { src: "/Thumbnail_0066.jpg" },
  { src: "/Thumbnail_0067.jpg" },
  { src: "/Thumbnail_0068.jpg" },
  { src: "/Thumbnail_0069.jpg" },
  { src: "/Thumbnail_0070.jpg" },
  { src: "/Thumbnail_0071.jpg" },
  { src: "/Thumbnail_0072.jpg" },
  { src: "/Thumbnail_0073.jpg" },
  { src: "/Thumbnail_0074.jpg" },
  { src: "/Thumbnail_0075.jpg" },
  { src: "/Thumbnail_0076.jpg" },
  { src: "/Thumbnail_0077.jpg" },
  { src: "/Thumbnail_0078.jpg" },
  { src: "/Thumbnail_0079.jpg" },
  { src: "/Thumbnail_0080.jpg" }
];
